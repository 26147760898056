import React from 'react';
import styles from './TabItem.module.css';

const TabItem = ({children, handleTabChange, index, selectedTabIndex, text, icon}) => {
    return (
        <div className={`${styles.container} ${index === selectedTabIndex ? styles.isCurrent : ''}`} onClick={() => handleTabChange(index)}>
            <div className={styles.iconContainer}>{icon}</div>
            <div className={styles.text}>{text}</div>
        </div>
    );
};

export default TabItem;