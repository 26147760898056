import React from 'react';
import styles from './Button.module.css';

const Button = ({children, disabled, isSecondary, className, ...rest}) => {
    return (
        <button className={`${styles.button} ${isSecondary ? styles.secondary : ''} ${className || ''}`} disabled={disabled} {...rest}>
            {children}
        </button>
    );
};

export default Button;