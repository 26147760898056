import {lazy, Suspense} from 'react';
import styles from './App.module.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import AuthPage from './pages/admin/AuthPage';

const NotFoundPage = lazy(() => import('./pages/NotFoundPage'));
const EmployeePage = lazy(() => import('./pages/EmployeePage'));
const CustomerPage = lazy(() => import('./pages/CustomerPage'));

const AdminPage = lazy(() => import('./pages/admin/AdminPage'));
const CompaniesPage = lazy(() => import('./pages/admin/CompaniesPage'));
const OrdersPage = lazy(() => import('./pages/admin/OrdersPage'));
const OrderPage = lazy(() => import('./pages/admin/OrderPage'));
const IntegrationPage = lazy(() => import('./pages/admin/IntegrationPage'));

function App() {
  return (
    <div className={styles.app}>
        <BrowserRouter basename={process.env.PUBLIC_URL}>
            <Suspense>
                <Routes>
                    <Route path='/c/:orderId' element={<CustomerPage/>}/>
                    <Route path='/employee/:orderId' element={<EmployeePage/>}/>
                    <Route path='/auth' element={<AuthPage/>}/>
                    <Route path='/admin' element={<AdminPage/>}>
                        <Route path='companies/' element={<CompaniesPage/>}/>
                        <Route path='companies/:companyId/orders' element={<OrdersPage/>}/>
                        <Route path='order/:id' element={<OrderPage/>}/>
                        <Route path='integration' element={<IntegrationPage/>}/>
                        <Route path='*' element={<NotFoundPage/>}/>
                    </Route>
                    <Route path='*' element={<NotFoundPage/>}/>
                </Routes>
            </Suspense>
        </BrowserRouter>

    </div>
  );
}

export default App;
