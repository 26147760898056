import orderReducer from './order';
import ordersReducer from './orders';
import companiesReducer from './companies';
import userReducer from './user';

import {configureStore, combineReducers} from "@reduxjs/toolkit";
import {tokenPersistMiddleware} from './middleware';

const reducer = combineReducers({
    order: orderReducer,
    orders: ordersReducer,
    companies: companiesReducer,
    user: userReducer,
})


const store = configureStore({
    reducer: reducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().prepend(tokenPersistMiddleware.middleware),
});

export default store;