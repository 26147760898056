import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import {checkResponseForThrowable, fetchWithAuth} from '../utils/Api';
import axios from 'axios';
const initialState = {
    order: {

    },
    orderLoading: false,
    orderUpdating: false,
    orderVideoUpdating: false,
    orderError: '',
    orderSubmitError: '',
};

//Selectors

const selectOrderState = (rootState) => rootState.order;
export const selectOrder = (rootState) => selectOrderState(rootState).order;
export const selectOrderLoading = (rootState) => selectOrderState(rootState).orderLoading;
export const selectOrderUpdating = (rootState) => selectOrderState(rootState).orderUpdating;
export const selectOrderVideoUpdating = (rootState) => selectOrderState(rootState).orderVideoUpdating;
export const selectOrderError = (rootState) => selectOrderState(rootState).orderError;
export const selectOrderSubmitError = (rootState) => selectOrderState(rootState).orderSubmitError;

//Actions

export const loadOrder = createAsyncThunk('LOAD_ORDER', async (orderId, thunkApi) => {
    const response = await fetchWithAuth(thunkApi,`${process.env.REACT_APP_API_BASE}/orders/${orderId}`);
    await checkResponseForThrowable(response);
    const order = await response.json();
    return {order};
});

export const setReadyToBeSent = createAsyncThunk('SET_ORDER_EMPLOYEE_FINISHED', async (payload) => {
    const response = await fetch(`${process.env.REACT_APP_API_BASE}/employee/orders/${payload.id}/set-ready`, {
        method: 'PUT',
        headers: {'Content-Type': 'application/json'},
    });
    await checkResponseForThrowable(response);
    return await response.json();
});

export const attachOrderVideo = createAsyncThunk('ATTACH_ORDER_VIDEO', async (payload) => {
    const response = await fetch(`${process.env.REACT_APP_API_BASE}/employee/orders/${payload.id}/update-video-url`, {
        method: 'PUT',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({
            videoUrl: payload.videoUrl
        })
    });
    await checkResponseForThrowable(response);
    return await response.json();
});

export const submitWorkItem = createAsyncThunk('SUBMIT_WORK_ITEM', async (payload, thunkApi) => {
    const response = await fetchWithAuth(thunkApi,`${process.env.REACT_APP_API_BASE}/orders/${payload.orderId}/offered-works`, {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(payload.workItem)
    });
    await checkResponseForThrowable(response);
    return await response.json();
});

export const removeOfferedWorkItem = createAsyncThunk('DELETE_WORK_ITEM', async (payload, thunkApi) => {
    const response = await fetchWithAuth(thunkApi,`${process.env.REACT_APP_API_BASE}/offered-works/${payload.id}`, {
        method: 'DELETE',
    });
    await checkResponseForThrowable(response, 'Не удалось удалить работу');
});

export const updateOfferedWorkItem = createAsyncThunk('UPDATE_WORK_ITEM', async (payload, thunkApi) => {
    const response = await fetchWithAuth(thunkApi,`${process.env.REACT_APP_API_BASE}/offered-works/${payload.workItem.id}`, {
        method: 'PUT',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(payload.workItem)
    });
    await checkResponseForThrowable(response, 'Не удалось обновить работу');
    return response.json();
});

export const loadOrderForEmployee = createAsyncThunk('LOAD_ORDER_EMPLOYEE', async (orderId) => {
    const response = await fetch(`${process.env.REACT_APP_API_BASE}/employee/orders/${orderId}`);
    await checkResponseForThrowable(response);
    return await response.json();
});

export const submitWorkItemForEmployee = createAsyncThunk('SUBMIT_WORK_ITEM_EMPLOYEE', async (payload, thunkAPI) => {
    const response = await fetch(`${process.env.REACT_APP_API_BASE}/employee/orders/${payload.orderId}/offered-works`, {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(payload.workItem)
    });
    await checkResponseForThrowable(response);
    return await response.json();
});

export const removeOfferedWorkItemForEmployee = createAsyncThunk('DELETE_WORK_ITEM_EMPLOYEE', async (payload, thunkAPI) => {
    const response = await fetch(`${process.env.REACT_APP_API_BASE}/employee/offered-works/${payload.id}`, {
        method: 'DELETE',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({orderId: payload.orderId})
    });
    await checkResponseForThrowable(response, 'Не удалось удалить работу');
});

export const updateOfferedWorkItemForEmployee = createAsyncThunk('UPDATE_WORK_ITEM_EMPLOYEE', async (payload, thunkAPI) => {
    const response = await fetch(`${process.env.REACT_APP_API_BASE}/employee/offered-works/${payload.workItem.id}`, {
        method: 'PUT',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(payload.workItem)
    });
    await checkResponseForThrowable(response, 'Не удалось обновить работу');
    return response.json();
});

export const loadOrderForCustomer = createAsyncThunk('LOAD_ORDER_CUSTOMER', async (orderId) => {
    const response = await fetch(`${process.env.REACT_APP_API_BASE}/customer/orders/${orderId}`);
    await checkResponseForThrowable(response);
    return await response.json();
});

export const setOrderConfirmation = createAsyncThunk('SET_ORDER_CONFIRMATION', async (payload) => {
    const response = await fetch(`${process.env.REACT_APP_API_BASE}/customer/orders/${payload.id}/confirm`, {
        method: 'PUT',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({
            acceptedWorkIds: payload.acceptedWorkIds,
            customerComment: payload.customerComment,
        })
    });
    await checkResponseForThrowable(response);
    return await response.json();
});

export const updateOrderCustomerActionStats = createAsyncThunk('UPDATE_ORDER_STATS', async (payload) => {
    const response = await fetch(`${process.env.REACT_APP_API_BASE}/customer/orders/${payload.id}/stats`, {
        method: 'PUT',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({
            pageOpened: payload.pageOpened,
            videoPlayed: payload.videoPlayed,
        })
    });
    await checkResponseForThrowable(response);
    return await response.json();
});

export const uploadOrderVideo = createAsyncThunk('UPLOAD_ORDER_VIDEO', async (payload) => {
    const response = await axios.post(
        `${process.env.REACT_APP_API_BASE}/employee/orders/${payload.orderId}/video`,
        payload.formData,
        {
            onUploadProgress: payload.onUploadProgress,
            onDownloadProgress: (progressEvent => {
                console.log(progressEvent);
            })
        }
    );
    await checkResponseForThrowable(response);
    return response.data;
});

//Reducers

const orderSlice = createSlice({
    name: 'order',
    initialState,
    reducers: {
        toggleOfferedWorkAcceptance: (state, action) => {
            const targetWork = state.order.offeredWorks.find(w => w.id === action.payload);
            targetWork.isAccepted = !targetWork.isAccepted;
        },
        changeCustomerComment: (state, action) => {
            state.order.customerComment = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(loadOrder.pending, (state, action) => {
            state.orderLoading = true;
        })
            .addCase(loadOrder.fulfilled, (state, action) => {
                state.orderLoading = false;
                state.order = action.payload.order;
            })
            .addCase(loadOrder.rejected, (state, action) => {
                state.orderLoading = false;
                state.orderError = 'Ошибка при загрузке данных о заказе';
            })
            .addCase(submitWorkItem.pending, (state, action) => {
                state.orderUpdating = true;
            })
            .addCase(submitWorkItem.fulfilled, (state, action) => {
                state.orderUpdating = false;
                state.order.offeredWorks.push(action.payload);
            })
            .addCase(submitWorkItem.rejected, (state, action) => {
                state.orderUpdating = false;
                state.orderSubmitError = 'Ошибка при добавлении данных: ' + action.error.message;
            })
            .addCase(submitWorkItemForEmployee.pending, (state, action) => {
                state.orderUpdating = true;
            })
            .addCase(submitWorkItemForEmployee.fulfilled, (state, action) => {
                state.orderUpdating = false;
                state.order.offeredWorks.push(action.payload);
            })
            .addCase(submitWorkItemForEmployee.rejected, (state, action) => {
                state.orderUpdating = false;
                state.orderSubmitError = 'Ошибка при добавлении данных: ' + action.error.message;
            })
            .addCase(updateOfferedWorkItem.pending, (state, action) => {
                state.orderUpdating = true;
            })
            .addCase(updateOfferedWorkItem.fulfilled, (state, action) => {
                state.orderUpdating = false;
                const index = state.order.offeredWorks.findIndex(w => w.id === action.payload.id);
                state.order.offeredWorks[index] = action.payload;
            })
            .addCase(updateOfferedWorkItem.rejected, (state, action) => {
                state.orderUpdating = false;
                state.orderSubmitError = 'Ошибка при обновлении данных: ' + action.error.message;
            })
            .addCase(updateOfferedWorkItemForEmployee.pending, (state, action) => {
                state.orderUpdating = true;
            })
            .addCase(updateOfferedWorkItemForEmployee.fulfilled, (state, action) => {
                state.orderUpdating = false;
                const index = state.order.offeredWorks.findIndex(w => w.id === action.payload.id);
                state.order.offeredWorks[index] = action.payload;
            })
            .addCase(updateOfferedWorkItemForEmployee.rejected, (state, action) => {
                state.orderUpdating = false;
                state.orderSubmitError = 'Ошибка при обновлении данных: ' + action.error.message;
            })
            .addCase(removeOfferedWorkItem.pending, (state, action) => {
                state.orderUpdating = true;
            })
            .addCase(removeOfferedWorkItem.fulfilled, (state, action) => {
                state.orderUpdating = false;
                state.order = {...state.order, offeredWorks: state.order.offeredWorks.filter(w => w.id !== action.meta.arg.id)};
            })
            .addCase(removeOfferedWorkItem.rejected, (state, action) => {
                state.orderUpdating = false;
                state.orderSubmitError = 'Ошибка при удалении работы: ' + action.error.message;
            })
            .addCase(removeOfferedWorkItemForEmployee.pending, (state, action) => {
                state.orderUpdating = true;
            })
            .addCase(removeOfferedWorkItemForEmployee.fulfilled, (state, action) => {
                state.orderUpdating = false;
                state.order = {...state.order, offeredWorks: state.order.offeredWorks.filter(w => w.id !== action.meta.arg.id)};
            })
            .addCase(removeOfferedWorkItemForEmployee.rejected, (state, action) => {
                state.orderUpdating = false;
                state.orderSubmitError = 'Ошибка при удалении работы: ' + action.error.message;
            })
            .addCase(setReadyToBeSent.pending, (state, action) => {
                state.orderUpdating = true;
            })
            .addCase(setReadyToBeSent.fulfilled, (state, action) => {
                state.orderUpdating = false;
                state.order = action.payload;
            })
            .addCase(setReadyToBeSent.rejected, (state, action) => {
                state.orderUpdating = false;
                state.orderSubmitError = 'Ошибка отправки данных: ' + action.error.message;
            })
            .addCase(attachOrderVideo.pending, (state, action) => {
                state.orderUpdating = true;
            })
            .addCase(attachOrderVideo.fulfilled, (state, action) => {
                state.orderUpdating = false;
                state.order = action.payload;
            })
            .addCase(attachOrderVideo.rejected, (state, action) => {
                state.orderUpdating = false;
                state.orderSubmitError = 'Ошибка загрузки видео: ' + action.error.message;
            })
            .addCase(uploadOrderVideo.pending, (state, action) => {
                state.orderVideoUpdating = true;
            })
            .addCase(uploadOrderVideo.fulfilled, (state, action) => {
                state.orderVideoUpdating = false;
                console.log(action.payload);
                state.order = action.payload;
            })
            .addCase(uploadOrderVideo.rejected, (state, action) => {
                state.orderVideoUpdating = false;
                state.orderSubmitError = 'Ошибка загрузки видео: ' + action.error.message;
            })
            .addCase(loadOrderForCustomer.pending, (state, action) => {
                state.orderLoading = true;
            })
            .addCase(loadOrderForCustomer.fulfilled, (state, action) => {
                state.orderLoading = false;
                state.order = action.payload;
            })
            .addCase(loadOrderForCustomer.rejected, (state, action) => {
                state.orderLoading = false;
                state.orderError = 'Ошибка при загрузке данных о заказе';
            })
            .addCase(setOrderConfirmation.pending, (state, action) => {
                state.orderUpdating = true;
            })
            .addCase(setOrderConfirmation.fulfilled, (state, action) => {
                state.orderUpdating = false;
                state.order = action.payload;
            })
            .addCase(setOrderConfirmation.rejected, (state, action) => {
                state.orderUpdating = false;
                state.orderSubmitError = 'Ошибка отправки данных';
            })
            .addCase(loadOrderForEmployee.pending, (state, action) => {
                state.orderLoading = true;
            })
            .addCase(loadOrderForEmployee.fulfilled, (state, action) => {
                state.orderLoading = false;
                state.order = action.payload;
            })
            .addCase(loadOrderForEmployee.rejected, (state, action) => {
                state.orderLoading = false;
                state.orderError = 'Ошибка при загрузке данных о заказе';
            })
    }
});

// const reducer = createReducer(initialState, );
export const { toggleOfferedWorkAcceptance, changeCustomerComment } = orderSlice.actions;

export default orderSlice.reducer;