import React from 'react';
import styles from './Loading.module.css';

const Loading = ({color = 'black', diameter = 50, width = 5}) => {
    return (
        <div className={styles.spinner} style={{borderColor: `${color} transparent ${color} transparent`, width: `${diameter}px`, height: `${diameter}px`, borderWidth: `${width}px`}}>
        </div>
    );
};

export default Loading;