import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {checkResponseForThrowable, fetchWithAuth} from '../utils/Api';

const initialState = {
    companies: [],
    companiesLoading: false,
    companiesUpdating: false,
    companiesLoadError: false,
    companiesUpdateError: false,
};

//Selectors

const selectCompaniesState = (rootState) => rootState.companies;
export const selectCompanies = (rootState) => selectCompaniesState(rootState).companies;
export const selectCompaniesLoading = (rootState) => selectCompaniesState(rootState).companiesLoading;
export const selectCompaniesUpdating = (rootState) => selectCompaniesState(rootState).companiesUpdating;
export const selectCompaniesLoadError = (rootState) => selectCompaniesState(rootState).companiesLoadError;
export const selectCompaniesUpdateError = (rootState) => selectCompaniesState(rootState).companiesUpdateError;

//Actions

export const loadCompanies = createAsyncThunk('LOAD_COMPANIES', async (companyId, thunkApi) => {
    const response = await fetchWithAuth(thunkApi, `${process.env.REACT_APP_API_BASE}/companies/`);
    await checkResponseForThrowable(response);
    return  await response.json();
});

export const createCompany = createAsyncThunk('CREATE_COMPANY', async (payload, thunkApi) => {
    const response = await fetchWithAuth(thunkApi,`${process.env.REACT_APP_API_BASE}/companies/`,
        {
            method: 'POST',
            headers: {'content-type':'application/json'},
            body: JSON.stringify(payload.newCompany)
        });
    await checkResponseForThrowable(response);
    return response.json();
});

export const removeCompany = createAsyncThunk('DELETE_COMPANY', async (payload, thunkApi) => {
    console.log(payload);
    const response = await fetchWithAuth(thunkApi,`${process.env.REACT_APP_API_BASE}/companies/${payload.id}`,
        {
            method: 'DELETE'
        });
    await checkResponseForThrowable(response);
});

export const updateCompany = createAsyncThunk('UPDATE_COMPANY', async (payload, thunkApi) => {
    const response = await fetchWithAuth(thunkApi,`${process.env.REACT_APP_API_BASE}/companies/${payload.company.id}`,
        {
            method: 'PUT',
            headers: {'content-type':'application/json'},
            body: JSON.stringify(payload.company)
        });
    await checkResponseForThrowable(response);
    return  response.json();
});

const companiesSlice = createSlice({
    name: 'companies',
    initialState,
    extraReducers: (builder) => {
        builder.addCase(loadCompanies.pending, (state, action) => {
            state.companiesLoading = true;
        })
        .addCase(loadCompanies.fulfilled, (state, action) => {
            state.companiesLoading = false;
            state.companies = action.payload;
        })
        .addCase(loadCompanies.rejected, (state, action) => {
            state.companiesLoading = false;
            state.companiesLoadError = 'Ошибка при загрузке компаний';
        })
        .addCase(createCompany.pending, (state, action) => {
            state.companiesUpdating = true;
        })
        .addCase(createCompany.fulfilled, (state, action) => {
            state.companiesUpdating = false;
            state.companies.unshift(action.payload);
        })
        .addCase(createCompany.rejected, (state, action) => {
            state.companiesUpdating = false;
            state.companiesUpdateError = 'Ошибка при создании компании';
        })
        .addCase(removeCompany.pending, (state, action) => {
            state.companiesUpdating = true;
            const companyIndex = state.companies.findIndex(o => o.id === action.meta.arg.id);
            state.companies[companyIndex].isUpdating = true;
        })
        .addCase(removeCompany.fulfilled, (state, action) => {
            state.companiesUpdating = false;
            const companyIndex = state.companies.findIndex(o => o.id === action.meta.arg.id);
            state.companies[companyIndex].isUpdating = false;
            state.companies.splice(companyIndex, 1);
        })
        .addCase(removeCompany.rejected, (state, action) => {
            state.companiesUpdating = false;
            const companyIndex = state.companies.findIndex(o => o.id === action.meta.arg.id);
            state.companies[companyIndex].isUpdating = false;
            state.companiesUpdateError = 'Ошибка при удалении компании';
        })
        .addCase(updateCompany.pending, (state, action) => {
            state.companiesUpdating = true;
        })
        .addCase(updateCompany.fulfilled, (state, action) => {
            state.companiesUpdating = false;
            const companyIndex = state.companies.findIndex(o => o.id === action.meta.arg.company.id);
            state.companies[companyIndex] = action.payload;
        })
        .addCase(updateCompany.rejected, (state, action) => {
            state.companiesUpdating = false;
            state.ordersUpdateError = 'Ошибка при обновлении компании';
        })
    }
});

export default companiesSlice.reducer;