import React, {forwardRef} from 'react';
import styles from './Input.module.css';
import {maskFloat} from '../../utils/Numbers';

const Input = forwardRef(({value, onValueChange, className, type, readOnly, style, ...rest}, ref) => {
    const typesMap = {decimal: 'text'};
    const handleInput = (event) => {
        if(event.target.value !== null) {
            if (type === 'decimal')
                event.target.value = maskFloat(event.target.value);
        }
    };

    return (
        <input className={`${styles.input} ${className}`}
               style={style}
               value={value}
               onChange={onValueChange}
               onInput={handleInput}
               type={typesMap[type] || type}
               readOnly={readOnly}
               ref={ref}
               {...rest}
        />
    );
});

export default Input;