import * as argon2 from 'argon2-browser/dist/argon2-bundled.min';
import cryptoRandomString from 'crypto-random-string';
export async function clientHashPassword(plaintextPassword, providedSalt = false){
    try{
        const clientSalt = providedSalt ? providedSalt : cryptoRandomString({length: 16, type: 'base64'});
        const result = await argon2.hash({pass: plaintextPassword, salt: clientSalt, type: argon2.ArgonType.Argon2id});
        return result.encoded;
    }catch (e) {
        let errorMessage = `Не удалось безопасно преобразовать пароль. ${!providedSalt ? 'Попробуйте ввести другой вариант' : 'Повторите попытку позже'}`;
        throw Error(errorMessage);
    }
}

export const tokenStorageKey = 'refreshToken'

export function setToken(token){
    if (token)
        localStorage.setItem(tokenStorageKey, JSON.stringify(token));
    else
        localStorage.removeItem(tokenStorageKey);
}

export function getToken(){
    return JSON.parse(localStorage.getItem(tokenStorageKey));
}