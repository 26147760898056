import React, {useEffect, useState} from 'react';
import styles from './AuthPage.module.css';
import {useNavigate} from 'react-router-dom';
import AuthForm from '../../bricks/AuthForm';
import TabItem from '../../bricks/TabItem';
import {ReactComponent as PersonIcon} from '../../../assets/person-icon.svg';
import {ReactComponent as PeopleIcon} from '../../../assets/people-icon.svg';
import {getPersistedRefreshToken, selectUser} from '../../../redux/user';
import {useSelector, useDispatch} from 'react-redux';

const AuthPage = () => {
    const dispatch = useDispatch();
    const user = useSelector(selectUser);
    const navigate = useNavigate();
    const signedInRedirectTarget = '/admin/companies';
    const Tabs = {
        SIGN_IN: 'sign_in',
        SIGN_UP: 'sign_up',
    };
    const [currentTabIndex, setCurrentTabIndex] = useState(Tabs.SIGN_IN);
    useEffect(() => {
        const checkPersistedToken = async () => {
            const persistedToken = await dispatch(getPersistedRefreshToken());
            if(persistedToken.payload)
                navigate(signedInRedirectTarget);
        };
        if(user?.id){
            navigate(signedInRedirectTarget);
        }else{
            checkPersistedToken();
        }
    }, [user?.id, navigate, dispatch])
    return (
        <div className={styles.content}>
            <div>
                <div className={styles.tabs}>
                    <TabItem text='Вход'
                             icon={<PersonIcon/>} handleTabChange={setCurrentTabIndex} index={Tabs.SIGN_IN} selectedTabIndex={currentTabIndex} />
                    <TabItem text='Регистрация'
                             icon={<PeopleIcon/>} handleTabChange={setCurrentTabIndex} index={Tabs.SIGN_UP} selectedTabIndex={currentTabIndex} />
                </div>
                <AuthForm isSignUp={currentTabIndex === Tabs.SIGN_UP}/>
            </div>
        </div>
    );
};

export default AuthPage;