import {refreshTokens} from '../redux/user';

export async function checkResponseForThrowable(response, errorMessageIfEmpty){
    if(response.status.toString()[0] !== '2'){
        let message = '';
        try{
            message = response.data || (!response.bodyUsed ? await response.json() : '');
        }catch (error){
            message = errorMessageIfEmpty || response.statusMessage;
        }
        throw Error(message);
    }
}

export function createFormData(name, file){
    const formData = new FormData();
    formData.append(name, file);
    return formData;
}

let tokenRefreshPromise = null;

export async function fetchWithAuth(thunkApi, input, init = null){
    let appState = thunkApi.getState();
    let tokens = appState?.user.user?.tokens;

    init = init ?? {headers: {}};
    init.headers = init.headers ?? {'content-type':'application/json'};
    init.headers['Authorization'] = `Bearer ${tokens?.access}`;

    const response = await fetch(input, init);
    if(response.status.toString()[0] === '4'){
        const message = await response.clone().json();
        if(message.indexOf('jwt') !== -1){
            console.log(tokenRefreshPromise);
            if (!tokenRefreshPromise){
                tokenRefreshPromise = thunkApi.dispatch(refreshTokens({refreshToken: tokens?.refresh}));
            }
            await tokenRefreshPromise;
            tokenRefreshPromise = null;

            appState = thunkApi.getState();
            tokens = appState?.user.user?.tokens;
            init.headers['Authorization'] = `Bearer ${tokens?.access}`;
            return fetch(input, init);
        } else return response;
    } else return response;
}