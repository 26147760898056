import React, {useState} from 'react';
import styles from './AuthForm.module.css';
import Input from './Input';
import Loading from './Loading';
import Button from './Button';
import {requestSalt, selectUserUpdating, signIn, signUp} from '../../redux/user';
import {useDispatch, useSelector} from 'react-redux';
import {clientHashPassword} from '../../utils/Auth';

const AuthForm = ({isSignUp = false}) => {
    const credStructure = {
        email: '',
        pwd: '',
        pwdRepeated: '',
    };
    const [creds, setCreds] = useState(credStructure);
    const [lastEncounteredError, setLastEncounteredError] = useState(null);

    const dispatch = useDispatch();
    const userUpdating = useSelector(selectUserUpdating);

    const handleChange = (event, isCheckBox = false) => {
        setLastEncounteredError(null);
        const value = !isCheckBox ? event.target?.value : event.target?.checked;
        setCreds(prev => {return {...prev, [event.target?.name]: value}});
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        let saltForSignIn = null;
        if(!validateRequiredFields())
            return;
        if(!isSignUp){
            const saltRes = await dispatch(requestSalt({email: creds.email}));
            console.log(saltRes);
            if(saltRes.error) {
                setLastEncounteredError(saltRes.error);
                return;
            }else{
                saltForSignIn = saltRes.payload;
            }
        }
        const transferredCreds = {
            email: creds.email,
            hashedPwd: await clientHashPassword(creds.pwd, saltForSignIn)
        }
        const res = isSignUp ? await dispatch(signUp(transferredCreds)) : await dispatch(signIn(transferredCreds));
        if(res.error){
            setLastEncounteredError(res.error);
        }else{
            setLastEncounteredError(null);
        }
    };

    const validateRequiredFields = () => {
        if(isSignUp && creds.pwd !== creds.pwdRepeated){
            setLastEncounteredError({message: 'Пароли не совпадают'});
            return false;
        }
        return creds.email && creds.pwd && (!isSignUp || creds.pwdRepeated);
    };

    return (
        <form className={styles.form} onSubmit={handleSubmit}>
            <Input value={creds.email} name='email' placeholder='Адрес электронной почты'
                   type='text' onValueChange={handleChange} className={styles.fullWidthInput} autoFocus/>
            <Input value={creds.pwd} name='pwd' placeholder='Пароль'
                   type='password' onValueChange={handleChange} className={styles.fullWidthInput}/>
            {isSignUp ?
                <Input value={creds.pwdRepeated} name='pwdRepeated' placeholder='Повторите пароль'
                       type='password' onValueChange={handleChange} className={styles.fullWidthInput}/>
                : ''}
            {lastEncounteredError ? <div style={{color: 'red'}}>{lastEncounteredError.message}</div> : ''}
            <Button disabled={userUpdating} className={styles.submitButton}>
                {userUpdating ?
                    <>
                        Пожалуйста, подождите
                        <span style={{marginLeft: '10px'}}><Loading diameter={15} width={2} color='white'/> </span>
                    </>
                    :
                    isSignUp ? 'Создать аккаунт' : 'Войти'
                }

            </Button>
        </form>
    );
};

export default AuthForm;