export function round(num, decimalPlaces = 0) {
    const p = Math.pow(10, decimalPlaces);
    const n = (num * p) * (1 + Number.EPSILON);
    return Math.round(n) / p;
}

export function isFloat(num){
    return !Number.isInteger(num) && Number.isFinite(num);
}

export function maskFloat(number){
    if(number !== null){
        number = number.replace(/[^\d.,]/g, '');
        number = number.replace(/,/g, '.');
        const fPointIndex = number.indexOf('.');
        if(fPointIndex !== -1){
            const intPart = number.slice(0, fPointIndex);
            let floatPart = number.slice(fPointIndex + 1, fPointIndex + 3);
            floatPart = floatPart.replace('.', '');
            number = `${intPart}.${floatPart}`;
        }
    }
    return number;
};