import {createAsyncThunk, createSlice, isAnyOf} from '@reduxjs/toolkit';
import {checkResponseForThrowable, fetchWithAuth} from '../utils/Api';

import {tokenPersistMiddleware} from './middleware';
import {getToken, setToken} from '../utils/Auth';

const initialState = {
    user: {},
    userLoading: false,
    userUpdating: false,
    userLoadError: false,
    userUpdateError: false,
};

const selectUserState = (rootState) => rootState.user;
export const selectUser = (rootState) => selectUserState(rootState).user;
export const selectUserLoading = (rootState) => selectUserState(rootState).userLoading;
export const selectUserUpdating = (rootState) => selectUserState(rootState).userUpdating;
export const selectUserLoadError = (rootState) => selectUserState(rootState).userLoadError;
export const selectUserUpdateError = (rootState) => selectUserState(rootState).userUpdateError;

export const signUp = createAsyncThunk('SIGN_UP', async (payload) => {
    const response = await fetch(`${process.env.REACT_APP_API_BASE}/sign-up`,
        {
            method: 'POST',
            headers: {'content-type':'application/json'},
            body: JSON.stringify({email: payload.email, hashedPwd: payload.hashedPwd})
        });
    await checkResponseForThrowable(response);
    return response.json();
});

export const signIn = createAsyncThunk('SIGN_IN', async (payload) => {
    const response = await fetch(`${process.env.REACT_APP_API_BASE}/sign-in`,
        {
            method: 'POST',
            headers: {'content-type':'application/json'},
            body: JSON.stringify({email: payload.email, hashedPwd: payload.hashedPwd})
        });
    await checkResponseForThrowable(response);
    return response.json();
});

export const requestSalt = createAsyncThunk('REQUEST_SALT', async (payload) => {
    const response = await fetch(`${process.env.REACT_APP_API_BASE}/salt/${payload.email}`);
    await checkResponseForThrowable(response);
    return response.json();
});

export const getPersistedRefreshToken = createAsyncThunk('GET_TOKEN_PERSISTED', async () => {
    return getToken();
});

export const setPersistedRefreshToken = createAsyncThunk('SET_TOKEN_PERSISTED', async (token) => {
    setToken(token);
});

export const refreshTokens = createAsyncThunk('REFRESH_TOKENS', async (payload) => {
    const response = await fetch(`${process.env.REACT_APP_API_BASE}/token`,
        {
            method: 'POST',
            headers: {'Authorization': `Bearer ${payload.refreshToken}`},
        });
    await checkResponseForThrowable(response);
    return response.json();
});

export const refreshApiKey = createAsyncThunk('REFRESH_API_KEY', async (payload, thunkApi) => {
    const response = await fetchWithAuth(thunkApi, `${process.env.REACT_APP_API_BASE}/api-key`, {method: 'POST'});
    await checkResponseForThrowable(response);
    return response.json();
});

export const signOut = createAsyncThunk('SIGN_OUT', async (payload) => {
    const response = await fetch(`${process.env.REACT_APP_API_BASE}/sign-out`,
        {
            method: 'POST',
            headers: {'Authorization': `Bearer ${payload.refreshToken}`},
        });
    await checkResponseForThrowable(response);
});

export const getSelf = createAsyncThunk('LOAD_SELF', async (payload, thunkApi) => {
    const response = await fetchWithAuth(thunkApi,`${process.env.REACT_APP_API_BASE}/self`);
    await checkResponseForThrowable(response);
    return response.json();
});

export const updateAllowedIntegrationHosts = createAsyncThunk('UPDATE_ALLOWED_HOSTS', async (payload, thunkApi) => {
    const response = await fetchWithAuth(thunkApi, `${process.env.REACT_APP_API_BASE}/integration-hosts`,
        {
            method: 'POST',
            body: JSON.stringify({hosts: payload.hosts})
        });
    await checkResponseForThrowable(response);
    return response.json();
});

const userSlice = createSlice({
    name: 'user',
    initialState,
    extraReducers: (builder) => {
        builder
        .addCase(signUp.pending, (state, action) => {
            state.userUpdating = true;
        })
        .addCase(signUp.fulfilled, (state, action) => {
            state.userUpdating = false;
            state.user = action.payload;
        })
        .addCase(signUp.rejected, (state, action) => {
            state.userUpdating = false;
            state.userUpdateError = 'Ошибка при создании пользователя';
        })
        .addCase(signIn.pending, (state, action) => {
            state.userUpdating = true;
        })
        .addCase(signIn.fulfilled, (state, action) => {
            state.userUpdating = false;
            state.user = action.payload;
        })
        .addCase(signIn.rejected, (state, action) => {
            state.userUpdating = false;
            state.userUpdateError = 'Ошибка при попытке аутентификации';
        })
        .addCase(requestSalt.pending, (state, action) => {
            state.userUpdating = true;
        })
        .addCase(requestSalt.fulfilled, (state, action) => {
            state.userUpdating = false;
        })
        .addCase(requestSalt.rejected, (state, action) => {
            state.userUpdating = false;
            state.userUpdateError = 'Ошибка при получении необходимых для входа данных';
        })
        .addCase(refreshTokens.pending, (state, action) => {
            state.userLoading = true;
        })
        .addCase(refreshTokens.fulfilled, (state, action) => {
            state.userLoading = false;
            state.user.tokens = action.payload;
        })
        .addCase(refreshTokens.rejected, (state, action) => {
            state.userLoading = false;
            state.userLoadError = 'Ошибка при обновлении токенов';
        })
        .addCase(refreshApiKey.pending, (state, action) => {
            state.userLoading = true;
        })
        .addCase(refreshApiKey.fulfilled, (state, action) => {
            state.userLoading = false;
            state.user = {...action.payload, tokens: state.user.tokens};
        })
        .addCase(refreshApiKey.rejected, (state, action) => {
            state.userLoading = false;
            state.refreshApiKey = 'Ошибка при обновлении токенов';
        })
        .addCase(getSelf.pending, (state, action) => {
            state.userLoading = true;
        })
        .addCase(getSelf.fulfilled, (state, action) => {
            state.userLoading = false;
            state.user = {...action.payload, tokens: state.user.tokens};
        })
        .addCase(getSelf.rejected, (state, action) => {
            state.userLoading = false;
            state.userLoadError = 'Ошибка при обновлении токенов';
        })
        .addCase(signOut.pending, (state, action) => {
            state.userLoading = true;
        })
        .addCase(signOut.fulfilled, (state, action) => {
            state.userLoading = false;
            state.user = null;
        })
        .addCase(signOut.rejected, (state, action) => {
            state.userLoading = false;
            state.userLoadError = 'Ошибка при закрытии сессии';
        })
        .addCase(updateAllowedIntegrationHosts.pending, (state, action) => {
            state.userUpdating = true;
        })
        .addCase(updateAllowedIntegrationHosts.fulfilled, (state, action) => {
            state.userUpdating = false;
            state.user = {...action.payload, tokens: state.user.tokens};
        })
        .addCase(updateAllowedIntegrationHosts.rejected, (state, action) => {
            state.userUpdating = false;
            state.refreshApiKey = 'Ошибка при обновлении списка разрешенных адресов';
        })
    }
});

tokenPersistMiddleware.startListening({
    matcher: isAnyOf(signUp.fulfilled, signIn.fulfilled, refreshTokens.fulfilled, signOut.fulfilled),
    effect: async (action, listenerApi) => {
        listenerApi.dispatch(() => {
            const token = action.payload?.tokens?.refresh || action.payload?.refresh;
            if(token || !token && action.type.indexOf('SIGN_OUT') !== -1){
                setToken(token);
            }
        });
    },
})
export default userSlice.reducer;