import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {checkResponseForThrowable, fetchWithAuth} from '../utils/Api';

const initialState = {
    orders: [],
    ordersLoading: false,
    ordersUpdating: false,
    ordersError: false,
    ordersUpdateError: false,
};

//Selectors

const selectOrdersState = (rootState) => rootState.orders;
export const selectOrders = (rootState) => selectOrdersState(rootState).orders;
export const selectOrdersLoading = (rootState) => selectOrdersState(rootState).ordersLoading;
export const selectOrdersUpdating = (rootState) => selectOrdersState(rootState).ordersUpdating;
export const selectOrdersError = (rootState) => selectOrdersState(rootState).ordersError;

//Actions

export const loadOrders = createAsyncThunk('LOAD_ORDERS', async (companyId, thunkApi) => {
    const response = await fetchWithAuth(thunkApi, `${process.env.REACT_APP_API_BASE}/companies/${companyId}/orders/`);
    await checkResponseForThrowable(response);
    return  {orders: await response.json()};
});

export const removeOrder = createAsyncThunk('DELETE_ORDER', async (payload, thunkApi) => {

    const response = await fetchWithAuth(thunkApi, `${process.env.REACT_APP_API_BASE}/orders/${payload.id}`, {method: 'DELETE'});
    await checkResponseForThrowable(response);
});

export const createOrder = createAsyncThunk('CREATE_ORDER', async (payload, thunkApi) => {
    const response = await fetchWithAuth(thunkApi, `${process.env.REACT_APP_API_BASE}/companies/${payload.companyId}/orders/`,
        {
            method: 'POST',
            headers: {'content-type':'application/json'},
            body: JSON.stringify(payload.newOrder)
        });
    await checkResponseForThrowable(response);
    return  {order: await response.json()};
});

export const updateOrder = createAsyncThunk('UPDATE_ORDER', async (payload, thunkApi) => {
    const response = await fetchWithAuth(thunkApi, `${process.env.REACT_APP_API_BASE}/orders/${payload.order.id}`,
        {
            method: 'PUT',
            headers: {'content-type':'application/json'},
            body: JSON.stringify(payload.order)
        });
    await checkResponseForThrowable(response);
    return  {order: await response.json()};
});

export const toggleOrderSentStatus = createAsyncThunk('TOGGLE_ORDER_SENT_STATUS', async (payload, thunkApi) => {
    const response = await fetchWithAuth(thunkApi, `${process.env.REACT_APP_API_BASE}/orders/${payload.id}/toggle-sent-status`,
        {
            method: 'PUT',
            headers: {'content-type':'application/json'},
        });
    await checkResponseForThrowable(response);
    return  {order: await response.json()};
});

const ordersSlice = createSlice({
    name: 'orders',
    initialState,
    extraReducers: (builder) => {
        builder.addCase(loadOrders.pending, (state, action) => {
            state.ordersLoading = true;
        })
        .addCase(loadOrders.fulfilled, (state, action) => {
            state.ordersLoading = false;
            state.orders = action.payload.orders;
        })
        .addCase(loadOrders.rejected, (state, action) => {
            state.ordersLoading = false;
            state.ordersError = 'Ошибка при загрузке заказов';
        })
        .addCase(removeOrder.pending, (state, action) => {
            state.ordersUpdating = true;
            let orderIndex = state.orders.findIndex(o => o.id === action.meta.arg.id);
            console.log(action, orderIndex);
            state.orders[orderIndex].isUpdating = true;
        })
        .addCase(removeOrder.fulfilled, (state, action) => {
            state.ordersUpdating = false;
            let orderIndex = state.orders.findIndex(o => o.id === action.meta.arg.id);
            state.orders[orderIndex].isUpdating = false;
            state.orders.splice(orderIndex, 1);
        })
        .addCase(removeOrder.rejected, (state, action) => {
            state.ordersUpdating = false;
            let orderIndex = state.orders.findIndex(o => o.id === action.meta.arg.id);
            state.orders[orderIndex].isUpdating = false;
            state.ordersUpdateError = 'Ошибка при удалении заказа';
        })
        .addCase(createOrder.pending, (state, action) => {
            state.ordersUpdating = true;
        })
        .addCase(createOrder.fulfilled, (state, action) => {
            state.ordersUpdating = false;
            state.orders.unshift(action.payload.order);
        })
        .addCase(createOrder.rejected, (state, action) => {
            state.ordersUpdating = false;
            state.ordersUpdateError = 'Ошибка при создании заказа';
        })
        .addCase(updateOrder.pending, (state, action) => {
            state.ordersUpdating = true;
        })
        .addCase(updateOrder.fulfilled, (state, action) => {
            state.ordersUpdating = false;
            let orderIndex = state.orders.findIndex(o => o.id === action.meta.arg.order.id);
            state.orders[orderIndex] = action.payload.order;
        })
        .addCase(updateOrder.rejected, (state, action) => {
            state.ordersUpdating = false;
            state.ordersUpdateError = 'Ошибка при обновлении заказа';
        })
        .addCase(toggleOrderSentStatus.pending, (state, action) => {
            state.ordersUpdating = true;
        })
        .addCase(toggleOrderSentStatus.fulfilled, (state, action) => {
            state.ordersUpdating = false;
            let orderIndex = state.orders.findIndex(o => o.id === action.meta.arg.id);
            state.orders[orderIndex] = action.payload.order;
        })
        .addCase(toggleOrderSentStatus.rejected, (state, action) => {
            state.ordersUpdating = false;
            state.ordersUpdateError = 'Ошибка при изменении статуса отправки заказа';
        })
    }
});

export default ordersSlice.reducer;